#landing {
  background-color: #f5f5f5;
  .header {
    color: $navy;
    background-color: #fff;
    border-bottom: 1px solid $navy;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 45px;
    padding: $space-md;
    align-items: center;
    @media (min-width: 768px) {
      padding: $space-lg $space-xl;
      flex-direction: row;
      justify-content: space-between;
      height: 2*$space-xl;
    }
    h1 {
      font-size: 34px !important;
      text-transform: capitalize;
    }
    .trans-logo {
      height: 80px;
      width: 175px;
    }
    .cta {
      color: $theme-green !important;
      .fa-phone {
        margin-right: $space-sm;
      }
      .phone {
        color: $theme-green;
      }
    }
  }
  .no-bullet {
    list-style: none;
    padding-left: 0;
  }
  .container {
    padding-bottom: $space-lg;
    font-family: Arial, sans-serif;
    color: $navy;
    .well {
      h3 {
        letter-spacing: 0.005em;
        font-size: 22px;
        line-height: 30px;
        margin: $space-stack-sm;
      }
      .btn {
        font-size: 24px;
        padding: $space-sm $space-md;
        margin: $space-stack-lg;
      }
    }
    .yelp-review{
      margin-bottom: $space-md;
    }
    ul.benefits {
      margin: $space-stack-lg;
      font-size: 24px;
      li {
        margin: $space-stack-md;
        .focus {
          color: $blue;
        }
      }
    }
  }
}

/* COMMON PRICING STYLES */

.panel.price {
  border-radius: 0px;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  > .panel-heading {
    border-top: 3px solid $navy;
    padding: 0;
    border-radius: 0px;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    img {
      max-width: 100%;
    }
  }
  &:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    > .panel-heading {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2) inset;
    }
  }
  > .panel-heading {
    box-shadow: 0px 5px 0px rgba(50, 50, 50, 0.2) inset;
    text-shadow: 0px 3px 0px rgba(50, 50, 50, 0.6);
  }
}

.price .list-group-item {
  font-size: 20px;
  border-bottom-: 1px solid rgba(250, 250, 250, 0.5);
}

.panel.price .list-group-item {
  &:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  &:first-child {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}

.price .panel-footer {
  color: #fff;
  border-bottom: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.3);
}

.panel.price .btn {
  font-size: 20px;
  box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
  border: 0px;
}

/* green panel */

.price {
  &.panel-blue {
    border: none;
    > {
      .panel-heading {
        color: #fff;
        background-color: #608BB4;
        border-color: #78AEE1;
        border-bottom: 1px solid #78AEE1;
      }
      .panel-body {
        color: #fff;
        background-color: #73A3D4;
        .lead {
          text-shadow: 0px 3px 0px rgba(50, 50, 50, 0.3);
        }
      }
    }
    .list-group-item {
      color: #333;
      background-color: rgba(50, 50, 50, 0.01);
      font-weight: 600;
      text-shadow: 0px 1px 0px rgba(250, 250, 250, 0.75);
    }
  }
  &.panel-grey {
    > {
      .panel-heading {
        color: #fff;
        background-color: #6D6D6D;
        border-color: #B7B7B7;
        border-bottom: 1px solid #B7B7B7;
      }
      .panel-body {
        color: #fff;
        background-color: #808080;
        .lead {
          text-shadow: 0px 3px 0px rgba(50, 50, 50, 0.3);
        }
      }
    }
    .list-group-item {
      color: #333;
      background-color: rgba(50, 50, 50, 0.01);
      font-weight: 600;
      text-shadow: 0px 1px 0px rgba(250, 250, 250, 0.75);
    }
  }
  &.panel-white > .panel-heading {
    color: #333;
    background-color: #f9f9f9;
    border-color: #ccc;
    border-bottom: 1px solid #ccc;
    text-shadow: 0px 2px 0px rgba(250, 250, 250, 0.7);
  }
}

.panel.panel-white.price:hover > .panel-heading {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
}

.price {
  &.panel-white > .panel-body {
    color: #fff;
    background-color: #dfdfdf;
    .lead {
      text-shadow: 0px 2px 0px rgba(250, 250, 250, 0.8);
      color: #666;
    }
  }
  &:hover.panel-white > .panel-body .lead {
    text-shadow: 0px 2px 0px rgba(250, 250, 250, 0.9);
    color: #333;
  }
  &.panel-white .list-group-item {
    color: #333;
    background-color: rgba(50, 50, 50, 0.01);
    font-weight: 600;
    text-shadow: 0px 1px 0px rgba(250, 250, 250, 0.75);
  }
}

#contact {
  h3 {
    margin-bottom: 24px;
  }
}