@charset "utf-8";

// Example Defaults
// $base-font-family: Helvetica, Arial, sans-serif;
// $base-font-size:   16px;
// $small-font-size:  $base-font-size * 0.875;
// $base-line-height: 1.5;

// $spacing-unit:     30px;

// $text-color:       #111;
// $background-color: #fdfdfd;
// $brand-color:      #2a7ae2;

// $grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

// // Width of the content area
// $content-width:    800px;

// $on-palm:          600px;
// $on-laptop:        800px;

// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

// variables
$blue: #0099CC;
$cyan: #66CCFF;
$navy: #0073bb;
$theme-green: #173829;

// spacing
$space-inset-xs: 0 4px;
$space-inset-sm: 0 8px;
$space-inset-md: 0 16px;
$space-inset-lg: 0 32px;
$space-inset-xl: 0 64px;
$space-stack-xs: 4px 0;
$space-stack-sm: 8px 0;
$space-stack-md: 16px 0;
$space-stack-lg: 32px 0;
$space-stack-xl: 64px 0;
$space-xs: 4px;
$space-sm: 8px;
$space-md: 16px;
$space-lg: 32px;
$space-xl: 64px;

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins",
        "base",
        "landing"
;

/* banner */

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    height: 150px;
    color: #fff;
    filter: grayscale(40%);
    -webkit-filter: grayscale(40%);
    -moz-filter: grayscale(40%);
    background: url(../img/banner.jpg) no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.text-vertical-center {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

	    h1 {
		color:$navy;
        text-shadow: 2px 2px $blue;
	    margin: 0;
	    padding: 0;
	    font-size: 4.5em;
	    font-weight: 700;
	}
}

/* Map */

.map {
    height: 500px;
}

@media(max-width:768px) {
    .map {
        height: 75%;
    }
}

/*disable custom maps scrolling behaviour*/
.scrolloff {
    pointer-events: none;
}

.trans-logo {
	position:relative;
	float: left;
	height:50px;
	padding:0.8em;
	width: auto;
}

/*language option styple*/
.lang-option{
	padding-left:2em;
	font-size:90%;
}

/*Table*/

.pretty_table {
	margin:0px;padding:0px;
	width:100%;
	box-shadow: 10px 10px 5px #888888;
	border:1px solid #000000;

	-moz-border-radius-bottomleft:0px;
	-webkit-border-bottom-left-radius:0px;
	border-bottom-left-radius:0px;

	-moz-border-radius-bottomright:0px;
	-webkit-border-bottom-right-radius:0px;
	border-bottom-right-radius:0px;

	-moz-border-radius-topright:0px;
	-webkit-border-top-right-radius:0px;
	border-top-right-radius:0px;

	-moz-border-radius-topleft:0px;
	-webkit-border-top-left-radius:0px;
	border-top-left-radius:0px;
}.pretty_table table{
    border-collapse: collapse;
        border-spacing: 0;
	width:100%;
	height:100%;
	margin:0px;padding:0px;
}.pretty_table tr:last-child td:last-child {
	-moz-border-radius-bottomright:0px;
	-webkit-border-bottom-right-radius:0px;
	border-bottom-right-radius:0px;
}
.pretty_table table tr:first-child td:first-child {
	-moz-border-radius-topleft:0px;
	-webkit-border-top-left-radius:0px;
	border-top-left-radius:0px;
}
.pretty_table table tr:first-child td:last-child {
	-moz-border-radius-topright:0px;
	-webkit-border-top-right-radius:0px;
	border-top-right-radius:0px;
}.pretty_table tr:last-child td:first-child{
	-moz-border-radius-bottomleft:0px;
	-webkit-border-bottom-left-radius:0px;
	border-bottom-left-radius:0px;
}.pretty_table tr:hover td{

}
.pretty_table tr:nth-child(odd){ background-color:#aad4ff; }
.pretty_table tr:nth-child(even)    { background-color:#ffffff; }.pretty_table td{
	vertical-align:middle;


	border:1px solid #000000;
	border-width:0px 1px 1px 0px;
	text-align:left;
	padding:7px;
	font-size:10px;
	font-family:Arial;
	font-weight:normal;
	color:#000000;
}.pretty_table tr:last-child td{
	border-width:0px 1px 0px 0px;
}.pretty_table tr td:last-child{
	border-width:0px 0px 1px 0px;
}.pretty_table tr:last-child td:last-child{
	border-width:0px 0px 0px 0px;
}
.pretty_table tr:first-child td{
		background:-o-linear-gradient(bottom, #005fbf 5%, #003f7f 100%);	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #005fbf), color-stop(1, #003f7f) );
	background:-moz-linear-gradient( center top, #005fbf 5%, #003f7f 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#005fbf", endColorstr="#003f7f");	background: -o-linear-gradient(top,#005fbf,003f7f);

	background-color:#005fbf;
	border:0px solid #000000;
	text-align:center;
	border-width:0px 0px 1px 1px;
	font-size:14px;
	font-family:Arial;
	font-weight:bold;
	color:#ffffff;
}
.pretty_table tr:first-child:hover td{
	background:-o-linear-gradient(bottom, #005fbf 5%, #003f7f 100%);	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #005fbf), color-stop(1, #003f7f) );
	background:-moz-linear-gradient( center top, #005fbf 5%, #003f7f 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#005fbf", endColorstr="#003f7f");	background: -o-linear-gradient(top,#005fbf,003f7f);

	background-color:#005fbf;
}
.pretty_table tr:first-child td:first-child{
	border-width:0px 0px 1px 0px;
}
.pretty_table tr:first-child td:last-child{
	border-width:0px 0px 1px 1px;
}

/* hr */

.hr {
	background-color: $blue;
	border: 1px solid $blue;
	height: 2px;
	margin-top:1em;
	margin-bottom:1em;
	margin-left: auto;
	margin-right: auto;
	width: 70%;

}
